import { Component, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { LoadingController } from '@ionic/angular';
import { Answer } from '@models/answer.model';
import { Question } from '@models/question.model';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { Trivia2Service } from '@services/trivia2.service';

@Component({
  selector: 'app-trivia',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.scss'],
})
export class TriviaPage extends Utilities implements OnInit {

  questions: Question[];
  question: Question;
  fakes = [
    {name: 'dumb'},
    {name: 'dumb'},
    {name: 'dumb'},
    {name: 'dumb'},
  ];

  // showResult shows a correct/wrong/neither based on 2/1/0 respectively
  showResult = 0;

  constructor(
    private triviaService: Trivia2Service,
    private bgController: BrainGameControllerService,
    public loadingCtrl: LoadingController
  ) { super();}

  ngOnInit() {
    this.showLoading();
    this.bgController.timerPaused.next(true);
    // put questions into service instead of here and pull them as needed
    if(!this.questions){
      this.triviaService.getEnoughQuestions().then((questions: Question[]) => {
        this.questions = questions;
        this.question = this.questions[this.qIndex];
        this.bgController.timerPaused.next(false);
        this.hidLoading();
      }).catch((err) => {
        throw new Error(err)
        this.showResult = -1;
      });
    } else {
      console.log('DONE WITH QUESTIONS');
    }
    
    
    this.bgController.timeUp.subscribe(() => {
      if(!this.bgController.inGameFlag) {
        this.timeUpHandler();
      }
    });
  }

  clickAnswer(answer: Answer, question: Question) {
    if(question.answer_id === answer.id) {
      this.showResult = 2;
    } else {
      this.showResult = 1;
    }
    this.nextQuestion(answer);
  }

  qIndex = 0;
  async nextQuestion(answer?: Answer) {
    this.bgController.timerPaused.next(true);
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    await wait(750).then( () => {
      this.showResult = 0;
      if(answer) {
        this.triviaService.questionAnswered(answer, this.question);
      }
    });
    
    if(this.questions.length < 2){
      this.questions = await this.triviaService.getEnoughQuestions();
    }
    this.question = this.questions[this.qIndex];
    if(this.question.imageUrl || this.qIndex%4 === 3) {
      // this.bgController.timerPaused.next(true);
    } else {
      this.bgController.timerPaused.next(false);
    }
  }

  timeUpHandler() {
    this.bgController.navAway('answers');
  }

  test(val: any) {
    if(val) {
      this.bgController.timerPaused.next(false);
    }
  }

}
