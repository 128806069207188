
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { BrainGameControllerService } from '@services/brain-game-controller.service';

import {
  SwiperPaginationInterface,
  SwiperComponent
} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-bg-instruction',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss'],
})
export class BGInstructionsComponent implements OnInit {
  @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;

  @Input()
  instructions = [{}];

  @Input()
  preGameTitle: string;

  @Input()
  gameClass = 'secondary';

  @Input()
  containerClass?: string;

  @Input()
  itemClass?: string;

  @Input()
  buttonClass = 'be-button-primary';

  @Output()
  instructionEnd: EventEmitter<any> = new EventEmitter();

  instructionIndex = 0;

  disablePrev = true;
  showTitle: boolean;
  instruction: any = {};
  
  constructor(
    // private dataService: DataTrackingService
    private bgController: BrainGameControllerService
  ) { }

  ngOnInit() {
    this.instruction = this.instructions[0];
    this.bgController.timerPaused.next(true);
    // this.dataService.startTimer('instructionTime');
  }


  /**
   * emits an event when the last instructions
   * is displayed
   */
  onInstructionEnded() {
    // this.dataService.endTimer('instructionTime', true);
    this.instructionEnd.emit();
  }

  /**
   * moves the next slide if we have not reached the end
   * of the instructions, otherwise emit an event so the
   * parent component can take action
   */
  moveToNext() {
    this.instructionIndex++;
    if (this.instructionIndex !== this.instructions.length) {
      // move to the next slide
      this.instruction = this.instructions[this.instructionIndex];
      this.disablePrev = false;
    } else {
      this.onInstructionEnded();
    }
  }

  /**
   * moves the prev slide if we have not reached the end
   * of the instructions, otherwise disable
   */
  moveToPrev() {
    if (this.instructionIndex > 0) {
      this.instructionIndex--;
      // move to the prev slide
      this.instruction = this.instructions[this.instructionIndex];
      this.disablePrev = this.instructionIndex === 0;
    }
  }
}
