import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { AssessmentService } from './assessment.service';
import { HomeService } from './home.service';
import { AdminService } from './admin.service';
import { BGGame } from '@models/bgGame.model';
import { BrainGameReport } from '@models/bgReport.interface';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { DB_CONFIG } from '@app/app.firebase.config';

@Injectable({
  providedIn: 'root',
})
export class BrainGameService {
  public recommendedLevelsData: { difficulty: GameDifficultyEnum; game: PuzzleGameEnum }[] = [];
  public games: BGGame[] = [];

  // TODO: rework difficulty upgrading
  private readonly cutOffs = {
    prev3_less_than_value: 8,
    prev5_less_than_value: 14,
    prev3_greater_than_value: 16,
    prev5_greater_than_value: 26,
  };

  private brainGameLevelValues = {
    trivia: [
      {
        percentage: '> 79%',
        greater_than_value: 79,
        category: 'Green',
        level: GameDifficultyEnum.Hard,
        value: 3
      },
      {
        percentage: '65%-79%',
        max_value: 79,
        min_value: 65,
        category: 'Yellow',
        level: GameDifficultyEnum.Medium,
        value: 2
      },
      {
        percentage: '< 65%',
        less_than_value: 65,
        category: 'Red',
        level: GameDifficultyEnum.Easy,
        value: 1
      }
    ],
  
    assessment: [
      {
        percentage: '> 79%',
        greater_than_value: 7.9,
        category: 'Green',
        level: GameDifficultyEnum.Hard,
        value: 3
      },
      {
        percentage: '60%-79%',
        max_value: 7.9,
        min_value: 6.0,
        category: 'Yellow',
        level: GameDifficultyEnum.Medium,
        value: 2
      },
      {
        percentage: '< 60%',
        less_than_value: 6.0,
        category: 'Red',
        level: GameDifficultyEnum.Easy,
        value: 1
      }
    ],
  
    lemonade:  [
      {
        score: '> 30',
        greater_than_value: 30,
        category: 'Green',
        value: 3
      },
      {
        score: '20-30',
        max_value: 30,
        min_value: 20,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 20',
        less_than_value: 20,
        category: 'Red',
        value: 1
      }
    ],
    sudoku:  [
      {
        score: '> 30',
        greater_than_value: 30,
        category: 'Green',
        value: 3
      },
      {
        score: '20-30',
        max_value: 30,
        min_value: 20,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 20',
        less_than_value: 20,
        category: 'Red',
        value: 1
      }
    ],
    puzzle_master:  [
      {
        score: '> 20',
        greater_than_value: 20,
        category: 'Green',
        value: 3
      },
      {
        score: '9-20',
        max_value: 20,
        min_value: 9,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 9',
        less_than_value: 9,
        category: 'Red',
        value: 1
      }
    ],
    synapse_scramble:  [
      {
        score: '> 20',
        greater_than_value: 20,
        category: 'Green',
        value: 3
      },
      {
        score: '9-20',
        max_value: 20,
        min_value: 9,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 9',
        less_than_value: 9,
        category: 'Red',
        value: 1
      }
    ],
    brain_defense:  [
      {
        score: '> 20',
        greater_than_value: 20,
        category: 'Green',
        value: 3
      },
      {
        score: '9-20',
        max_value: 20,
        min_value: 9,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 9',
        less_than_value: 9,
        category: 'Red',
        value: 1
      }
    ],
    large_sudoku:  [
      {
        score: '> 100',
        greater_than_value: 100,
        category: 'Green',
        value: 3
      },
      {
        score: '60-100',
        max_value: 100,
        min_value: 60,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 60',
        less_than_value: 60,
        category: 'Red',
        value: 1
      }
    ],
    the_fame_game:  [
      {
        score: '> 300',
        greater_than_value: 300,
        category: 'Green',
        value: 3
      },
      {
        score: '100-300',
        max_value: 300,
        min_value: 100,
        category: 'Yellow',
        value: 2
      },
      {
        score: '< 100',
        less_than_value: 100,
        category: 'Red',
        value: 1
      }
    ]
  };

  constructor(
    private adminService: AdminService,
    private assessmentService: AssessmentService,
    private homeService: HomeService
  ) {
    this.init();
  }

  init() {
    this.getGames();
    this.getBrainGameAnalyticsData();
  }

  /**
   * Retrieves the summary of the games within the specified date range.
   * @param userId The user ID.
   * @param fromDate The start date for filtering.
   * @param toDate The end date for filtering.
   * @returns A promise that resolves to the filtered responses.
   */
  async getSummaryAnswersByUserWithinDate(
    userId: string,
    fromDate: Date,
    toDate: Date
  ): Promise<{ responses: BrainGameReport[] }> {
    const responses = await this.adminService.getEntryById(userId, DB_CONFIG.bg_responses_endpoint)

    if (!responses || !responses.responses) {
      return { responses: [] };
    }

    const filteredResponses = responses.responses.filter((response: BrainGameReport) => {
      const responseDate = new Date(response.created_date);
      return responseDate >= fromDate && responseDate <= toDate;
    });

    return { responses: filteredResponses };
  }

  /**
   * Gets the Brain Game analytics data.
   * @param userId Optional user ID.
   * @returns A promise that resolves to the recommended levels data.
   */
  async getBrainGameAnalyticsData(userId?: string): Promise<{ difficulty: GameDifficultyEnum; game: PuzzleGameEnum }[]> {
    const authUserId = userId || (await this.homeService.getAuthUser())?.id;

    if (!authUserId) {
      throw new Error('User ID is required');
    }

    // Define date range for the past 3 months
    const fromDate = moment().subtract(3, 'months').toDate();
    const toDate = new Date();

    // Get summary answers from past 3 months
    const responseArr = await this.getSummaryAnswersByUserWithinDate(authUserId, fromDate, toDate);

    // Get Latest Assessment to Help Determine Level
    const assessmentData = await this.assessmentService.getAssessments(authUserId);
    const report = await this.assessmentService.calculateReport(assessmentData);
    const latestReport = report.length > 0 ? report[report.length - 1] : undefined;

    // Get Recommended Levels
    this.recommendedLevelsData = this.filterSummaryAnswersByGameType(responseArr.responses, latestReport);
    return this.recommendedLevelsData;
  }

  /**
   * Retrieves the list of games.
   * @returns A promise that resolves to the list of games.
   */
  async getGames(): Promise<BGGame[]> {
    if (this.games && this.games.length > 0) {
      return this.games;
    }

    try {
      this.games = await this.adminService.getEntries(DB_CONFIG.games_endpoint, 'name').pipe(take(1)).toPromise();

      return this.games;
    } catch (error) {
      console.error('Failed to load games', error);
      throw new Error('Failed to load games');
    }
  }

  /**
   * Filters summary answers by game type and determines recommended levels.
   * @param data The game data.
   * @param assessment The latest assessment report.
   * @returns An array of recommended levels per game type.
   */
  public filterSummaryAnswersByGameType(
    data: BrainGameReport[],
    assessment: any
  ): { difficulty: GameDifficultyEnum; game: PuzzleGameEnum }[] {
    // Ensure data is sorted by created_date descending
    data.sort(
      (a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime()
    );

    const recommendationLevels: { difficulty: GameDifficultyEnum; game: PuzzleGameEnum }[] = [];
    const puzzleGameTypes = Object.values(PuzzleGameEnum);

    for (const gameType of puzzleGameTypes) {
      let recommendedLevel: GameDifficultyEnum;

      const recentGameResults = this.getLatestGameResults(data, gameType);

      if (recentGameResults) {
        recommendedLevel = this.getRecommendedLevel(recentGameResults, gameType);
      } else if (assessment) {
        const strategyValue = this.assignStrategyValue(assessment.overall, 'assessment');
        recommendedLevel = strategyValue.level;
      } else {
        recommendedLevel = GameDifficultyEnum.Easy;
      }

      recommendationLevels.push({ difficulty: recommendedLevel, game: gameType });
    }

    return recommendationLevels;
  }

  /**
   * Retrieves the most recent 3-5 games of the same difficulty for the given game type.
   * @param data The game data.
   * @param gameName The game type.
   * @returns An object containing the games and difficulty or null if insufficient data.
   */
  public getLatestGameResults(
    data: BrainGameReport[],
    gameName: PuzzleGameEnum
  ): { data: BrainGameReport[]; difficulty: GameDifficultyEnum } | null {
    const selectedGameTypeData = data.filter((item) => item.game_name === gameName);
    const gamesByDifficulty: Record<GameDifficultyEnum, BrainGameReport[]> = {
      [GameDifficultyEnum.Easy]: [],
      [GameDifficultyEnum.Medium]: [],
      [GameDifficultyEnum.Hard]: [],
    };

    let selectedDifficulty: GameDifficultyEnum | null = null;

    for (const game of selectedGameTypeData) {
      const difficulty = game.difficulty as GameDifficultyEnum;

      if (!selectedDifficulty || selectedDifficulty === difficulty) {
        gamesByDifficulty[difficulty].push(game);

        if (gamesByDifficulty[difficulty].length === 3) {
          selectedDifficulty = difficulty;
        }

        if (gamesByDifficulty[difficulty].length === 5) {
          break;
        }
      }
    }

    if (!selectedDifficulty) {
      return null;
    }

    return {
      data: gamesByDifficulty[selectedDifficulty],
      difficulty: selectedDifficulty,
    };
  }

  /**
   * Determines the recommended level based on game data and current difficulty.
   * @param gameData The game data.
   * @param currentGameType The current game type.
   * @returns The recommended difficulty level.
   */
  public getRecommendedLevel(
    gameData: { data: BrainGameReport[]; difficulty: GameDifficultyEnum },
    currentGameType: string
  ): GameDifficultyEnum {
    const { data: gameDataValues, difficulty } = gameData;

    let levelUp: GameDifficultyEnum;
    let levelDown: GameDifficultyEnum;
    let strategyLevel: GameDifficultyEnum;

    switch (difficulty) {
      case GameDifficultyEnum.Easy:
        levelUp = GameDifficultyEnum.Medium;
        levelDown = GameDifficultyEnum.Easy;
        strategyLevel = GameDifficultyEnum.Easy;
        break;
      case GameDifficultyEnum.Medium:
        levelUp = GameDifficultyEnum.Hard;
        levelDown = GameDifficultyEnum.Easy;
        strategyLevel = GameDifficultyEnum.Medium;
        break;
      case GameDifficultyEnum.Hard:
        levelUp = GameDifficultyEnum.Hard;
        levelDown = GameDifficultyEnum.Medium;
        strategyLevel = GameDifficultyEnum.Hard;
        break;
      default:
        levelUp = GameDifficultyEnum.Easy;
        levelDown = GameDifficultyEnum.Easy;
        strategyLevel = GameDifficultyEnum.Easy;
        break;
    }

    let i = 0;
    let triviaSum3 = 0,
      triviaSum5 = 0;
    let strategySum3 = 0,
      strategySum5 = 0;

    for (const gameValue of gameDataValues) {
      const correctAnswers = gameValue.question_answer.filter((qa) => qa.correct).length;
      const triviaPercentage = (correctAnswers / gameValue.question_answer.length) * 100;
      const triviaStrategyValue = this.assignStrategyValue(triviaPercentage, 'trivia');
      const gameStrategyValue = this.assignStrategyValue(
        gameValue.game_score,
        currentGameType.toLowerCase()
      );

      if (i < 3) {
        triviaSum3 += triviaStrategyValue.value;
        strategySum3 += gameStrategyValue.value;
      }
      if (i < 5) {
        triviaSum5 += triviaStrategyValue.value;
        strategySum5 += gameStrategyValue.value;
      }

      i++;
      if (i >= 5) {
        break;
      }
    }

    const total3 = triviaSum3 + strategySum3;
    const total5 = triviaSum5 + strategySum5;

    if (
      total3 >= this.cutOffs.prev3_greater_than_value ||
      (i >= 5 && total5 >= this.cutOffs.prev5_greater_than_value)
    ) {
      return levelUp
    } else if (
      total3 <= this.cutOffs.prev3_less_than_value ||
      (i >= 5 && total5 <= this.cutOffs.prev5_less_than_value)
    ) {
      return levelDown;
    } else {
      return strategyLevel;
    }
  }

  /**
   * Assigns a strategy value based on the game score and strategy thresholds.
   * @param gameScore The game score.
   * @param strategy The strategy/game name.
   * @returns An object containing the category, level, and value.
   */
  public assignStrategyValue(
    gameScore: number,
    strategy: string
  ): { category: string; level: GameDifficultyEnum; value: number } {
    const strategyValues = this.brainGameLevelValues[strategy];
    if (!Array.isArray(strategyValues)) {
      throw new Error(`Invalid strategy: ${strategy}`);
    }

    for (const levelValue of strategyValues) {
      if (
        levelValue.greater_than_value !== undefined &&
        gameScore >= levelValue.greater_than_value
      ) {
        return {
          category: levelValue.category,
          level: levelValue.level || GameDifficultyEnum.Hard,
          value: levelValue.value,
        };
      } else if (
        levelValue.max_value !== undefined &&
        levelValue.min_value !== undefined &&
        gameScore <= levelValue.max_value &&
        gameScore >= levelValue.min_value
      ) {
        return {
          category: levelValue.category,
          level: levelValue.level || GameDifficultyEnum.Medium,
          value: levelValue.value,
        };
      } else if (
        levelValue.less_than_value !== undefined &&
        gameScore < levelValue.less_than_value
      ) {
        return {
          category: levelValue.category,
          level: levelValue.level || GameDifficultyEnum.Easy,
          value: levelValue.value,
        };
      }
    }

    // Default return if no condition matches
    return { category: '', level: GameDifficultyEnum.Easy, value: 0 };
  }

  /**
   * Converts a string to Title Case.
   * @param text The input string.
   * @returns The string in Title Case.
   */
  private toTitleCase(text: string): string {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  }
}